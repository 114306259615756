import type { Role } from "@/stores/auth"
import { useRouter, type NavigationFailure } from "vue-router"

// Utility function for getting matrix answers key
export const matrixAnswersName = (storeDataName: string): string => {
    return (
        storeDataName.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`) + '_answers'
    )
}

// Utility function that calculates if a dateString is within a certain number offset days
export const dateStringIsDaysBack = (dateString: string, daysBack: number) => {
    const createdDate = new Date(dateString)
    const today = new Date()
    const timeDiff = (today.getTime() - createdDate.getTime()) / (1000 * 3600 * 24)

    return timeDiff <= daysBack
}

// Utility function in the external file - pass router and store as arguments
export const pushToDashboard = async (role: Role | null, router: ReturnType<typeof useRouter>): Promise<NavigationFailure | void | undefined> => {
    if (role === "User") return router.push({ name: "ClientDashboard" })
    if (role === "Consultant" || role === "Admin") return router.push({ name: `${role}Dashboard` })
    return router.push("/")
}

