<template>
  <div class="relative inline-block text-left">
    <!-- Dropdown Toggle Button -->
    <button class="flex items-center focus:outline-none max-w-full space-x-2" @click="dropdownOpen = !dropdownOpen">
      <img :src="countryFlag" alt="Country flag" class="w-6 h-6 flex-shrink-0" data-test="selected-flag" />
      <span class="truncate">{{ languageName }}</span>
      <svg class="ml-2 h-4 w-4 flex-shrink-0" v-if="dropdownOpen" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
        fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd"
          d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd" />
      </svg>
      <svg class="ml-2 h-4 w-4 flex-shrink-0" v-if="!dropdownOpen" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd"
          d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd" />
      </svg>
    </button>

    <!-- Dropdown Menu -->
    <div v-if="dropdownOpen"
      class="absolute right-0 mt-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      @click="dropdownOpen = false">
      <div class="w-32 py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
        <div v-for="lang in langSummaries" :key="lang.language_code"
          class="flex cursor-pointer items-center px-4 py-2 text-black hover:bg-gray-100"
          @click="switchLocale(lang.language_code)" data-test="language-dropdown">
          <img :src="lang.language_code === 'fi' ? fiFlag : gbFlag" alt="Country flag" class="mr-2 w-1/2 align-baseline"
            :data-test="'language-' + lang.language_code" />
          {{ lang.name_in_native_language }}
        </div>
      </div>
    </div>
  </div>
</template>



<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useLanguageStore } from '@/stores/languages'
import { useI18n } from 'vue-i18n'

// https://flagicons.lipis.dev/
import fiFlag from '@/assets/flags/fi.svg'
import gbFlag from '@/assets/flags/gb.svg'
import { storeToRefs } from 'pinia'

interface LanguagePayload {
  country_code: string
  id: number
  language_code: "fi" | "en"
  name: string
  name_in_native_language: string
}

const t = useI18n()
const languageStore = useLanguageStore()
const { showLoading } = storeToRefs(languageStore);



// State
const dropdownOpen = ref(false)
const langSummaries = ref<LanguagePayload[]>()


// Lifecycle
try {
  const response = await languageStore.retrieveLanguages()
  langSummaries.value = response.data
} catch {
  // In case we can't retrieve, fall back to basic English
  // Note store handles displaying of error to the user
  langSummaries.value = [
    {
      country_code: "gb",
      id: 1,
      language_code: "en",
      name: "English",
      name_in_native_language: "English"
    }
  ]
}

const countryFlag = computed(() => {
  return t.locale.value === 'fi' ? fiFlag : gbFlag
})

const languageName = computed(() => {
  return t.locale.value === 'fi' ? "Suomi" : "English"
})

// Methods
const switchLocale = async (locale: 'fi' | 'en') => {
  if (t.locale.value !== locale) {
    showLoading.value = true
    await languageStore.handleLoadingLang(locale)
    showLoading.value = false
  }
}
</script>
