import { computed, ref, type Ref, type ComputedRef } from 'vue'
import axiosInstance from '@/axios'
import { type AxiosResponse } from 'axios'
import { defineStore } from 'pinia'
import { useAlertStore } from './alerts'
import { type Feedback } from './feedback'
import { pushToDashboard } from '@/utils/general'
import { useAuthStore } from './auth'
import { useRouter } from 'vue-router'

export class GrowthplanNotSetError extends Error {
	constructor(message: string, asserter=undefined) {
		super(message);
		Error.captureStackTrace?.(this, asserter || this.constructor);
	}
}

export type QuestionComponentName =
  | 'BenefitList'
  | 'CustomerMatrix'
  | 'CustomerTodo'
  | 'ProductMatrix'
  | 'ProductTodo'
  | 'SalesChannelsList'
  | 'SalesChannelsTodo'
  | 'LeadTools'
  | 'FollowerTools'
  | 'LeadFollowerTodo'
  | 'PeopleTodo'
  | 'FundingTodo'
  | 'SummaryAndSubmit'

export interface GrowthPlanStep {
  id: number
  name: QuestionComponentName
  position: number
  feedback: Feedback[]
}

export interface CompletedQuestion {
  id: number
  completed: boolean
  growthplan_step: GrowthPlanStep
}

export interface GrowthPlan {
  active: boolean
  business_unit: string
  completed: boolean
  id: number
  name: string
  percent_completed: number
  share_code: string | null
  shared: false
  start_date: string // See if this needs to be a Date or string is fine
  submitted_for_feedback: boolean
  user: {
    company: string
  }
  user_id: number
}

interface CreateGrowthplanPayload {
  name: string,
  business_unit?: string,
  copy_from_previous?: boolean,
  growthplan_to_copy?: number
}


interface GrowthplanStore {
  growthplans: Ref<GrowthPlan[]>
  activeGrowthplans: Ref<GrowthPlan[]>
  inActiveGrowthplans: Ref<GrowthPlan[]>
  selectedGrowthplan: Ref<GrowthPlan | null>
  currentGrowthplanStep: ComputedRef<GrowthPlanStep>
  completedQuestions: Ref<any[]>
  growthplanSteps: Ref<any[]>
  safeCurrentQuestion: Ref<number>
  currentQuestion: Ref<number>
  allQuestionsValid: ComputedRef<boolean>
  showConfirmationModal: Ref<boolean>

  $reset: () => void


  // Computed properties
  //
  currentQuestionComponent: ComputedRef<QuestionComponentName> 
  selectedGrowthplanId: ComputedRef<number>
  selectedGrowthplanName: ComputedRef<string>
  selectedGrowthplanBusinessUnit: ComputedRef<string>
  selectedGrowthplanActive: ComputedRef<boolean>
  selectedGrowthplanCompleted: ComputedRef<boolean>
  selectedGrowthplanPercentCompleted: ComputedRef<number>
  selectedGrowthplanShareCode: ComputedRef<string | null>
  selectedGrowthplanStartDate: ComputedRef<string>
  selectedGrowthplanSubmittedForFeedback: ComputedRef<boolean>
  selectedGrowthplanCompany: ComputedRef<string>

  saveGrowthplanPercentProgress: (percentageData: any) => Promise<any>
  createNewGrowthplan: (growthplanDetails: CreateGrowthplanPayload) => Promise<AxiosResponse<GrowthPlan, { msg: string }>>
  saveCompletedQuestions: (answerData: any) => Promise<any>
  retrieveCompletedQuestions: (growthplanId: any) => Promise<any>
  retrieveSharedGrowthplanSteps: (shareCode: any) => Promise<any>
  retrieveSharedGrowthplan: (shareCode: any) => Promise<any>
  retrieveUserGrowthplans: () => Promise<GrowthPlan[]>
  retrieveGrowthplanShareLink: (growthplanId: any) => Promise<any>
  toggleGrowthplanShared: (growthplanId: any) => Promise<any>
  setSubmittedForFeedback: (growthplanId: any) => Promise<any>
  setGrowthplanCompleted: (growthplanId: any) => Promise<any>
  retrieveAllTodo: (growthplanId: any) => Promise<any>
  saveDone: (payload: any) => Promise<any>
}

// NOTE: These are all correctly migrated
// TODO: Make this more typescript friendly
// TODO: Implement better error handling
export const useGrowthplanStore = defineStore(
  'growthplan',
  (): GrowthplanStore => {
    // Init other stores we want to use here
    const alertStore = useAlertStore()
    const authStore = useAuthStore()
    const router = useRouter()

    // State
    const growthplans = ref<GrowthPlan[]>([])
    const selectedGrowthplan = ref<GrowthPlan| null>(null)
    const completedQuestions = ref<CompletedQuestion[]>([])
    const growthplanSteps = ref<GrowthPlanStep[]>([])
    const currentQuestion = ref<number>(0)
    const showConfirmationModal = ref<boolean>(false)

    const $reset = () => {
      growthplans.value = []
      selectedGrowthplan.value = null
      completedQuestions.value = []
      growthplanSteps.value = []
      currentQuestion.value = 0
      showConfirmationModal.value = false
    }

    const currentQuestionComponent: ComputedRef<QuestionComponentName> = computed(() => {
      if (growthplanSteps.value && safeCurrentQuestion.value && growthplanSteps.value.length > 0) {
        return growthplanSteps.value[safeCurrentQuestion.value].name
      }
      return 'BenefitList'
    })

    const safeCurrentQuestion = computed(() => {
      if (currentQuestion.value >= growthplanSteps.value.length) {
        return growthplanSteps.value.length - 1
      }
      return currentQuestion.value
    })


    const currentGrowthplanStep = computed(() => {
      return growthplanSteps.value[currentQuestion.value]
    })

    const activeGrowthplans = computed((): GrowthPlan[] => growthplans.value.filter((growthplan) => growthplan.active))
    const inActiveGrowthplans = computed(() => growthplans.value.filter((g) => !g.active))

    const allQuestionsValid = computed(() => {
      return completedQuestions.value.slice(0, -1).every((question) => question.completed)
    })

    // Safe store
    // Facade like interface that ensures selectedGrowthplan is set, with one uniform way of handlign errors
    const safeSelectedGrowthplan = computed<GrowthPlan>(() => {
      if (selectedGrowthplan.value == null) {
        pushToDashboard(authStore.role, router)
        throw new GrowthplanNotSetError("Accessed Growth Plan property while not set!")
      }
      return selectedGrowthplan.value;
    });

    // Getters that will guarantee to yield the correct values, or will result in 
    // handleMissingGrowthPlanError to be called
    const selectedGrowthplanId = computed(() => safeSelectedGrowthplan.value.id);
    const selectedGrowthplanName = computed(() => safeSelectedGrowthplan.value.name);
    const selectedGrowthplanBusinessUnit = computed(() => safeSelectedGrowthplan.value.business_unit);
    const selectedGrowthplanActive = computed(() => safeSelectedGrowthplan.value.active);
    const selectedGrowthplanCompleted = computed(() => safeSelectedGrowthplan.value.completed);
    const selectedGrowthplanPercentCompleted = computed(() => safeSelectedGrowthplan.value.percent_completed);
    const selectedGrowthplanShareCode = computed(() => safeSelectedGrowthplan.value.share_code);
    const selectedGrowthplanStartDate = computed(() => safeSelectedGrowthplan.value.start_date);
    const selectedGrowthplanSubmittedForFeedback = computed(() => safeSelectedGrowthplan.value.submitted_for_feedback);
    const selectedGrowthplanCompany = computed(() => safeSelectedGrowthplan.value.user.company);

    // Methods
    const saveGrowthplanPercentProgress = async (percentageData: any) => {
      try {
        const response = await axiosInstance.patch(
          `/api/growthplan/${percentageData.growthplanId}/save/growthplan-progress`,
          {
            percent_completed: percentageData.percentCompleted
          }
        )
        return response
      } catch (error) {
        throw error
      }
    }

    const createNewGrowthplan = async (createGrowthplanPayload: CreateGrowthplanPayload) => {
      try {
        return await axiosInstance.post<GrowthPlan>('/api/growthplan/create/new-growthplan', createGrowthplanPayload)
      } catch (error) {
        throw error
      }
    }

    const saveCompletedQuestions = async (answerData: any) => {
      try {
        const requestURL = `/api/growthplan/${answerData.growthplanId}/save/completed-questions`
        const response = await axiosInstance.put(requestURL, answerData.data)
        return response
      } catch (error) {
        throw error
      }
    }

    const retrieveCompletedQuestions = async (growthplanId: number) => {
      try {
        // Retrieve data from the server
        const requestURL = `/api/growthplan/${growthplanId}/get/completed-questions`
        const response = await axiosInstance.get<CompletedQuestion[]>(requestURL)
        const retrievedGrowthplanSteps = response.data
        if (!Array.isArray(response.data) || response.data.length == 0) {
          throw Error("CompletedQuestions returned by backend were invalid.")
        }

        // Minor data manipulation
        const sortedGrowthplanSteps = retrievedGrowthplanSteps.sort((a, b) => {
          return a.growthplan_step.position - b.growthplan_step.position
        })

        // Set the values in the state directly, no mutations or setters needed in Pinia
        growthplanSteps.value = sortedGrowthplanSteps.map((x) => x.growthplan_step)
        completedQuestions.value = sortedGrowthplanSteps
      } catch (error) {
        throw error
      }
    }

    const retrieveSharedGrowthplanSteps = async (shareCode: any) => {
      try {
        const response = await axiosInstance.get<CompletedQuestion[]>(`/api/shared/get/growthplan-steps/${shareCode}`)
        const steps = response.data

        // Minor data manipulation
        const sortedGrowthplanSteps = steps.sort((a, b) => {
          return a.growthplan_step.position - b.growthplan_step.position
        })

        if (!Array.isArray(response.data) || response.data.length == 0) {
          throw Error("CompletedQuestions returned by backend were invalid.")
        }

        // Set the values in the state directly, no mutations or setters needed in Pinia
        growthplanSteps.value = sortedGrowthplanSteps.map((x) => x.growthplan_step)
        completedQuestions.value = sortedGrowthplanSteps

      } catch (error) {
        throw error
      }
    }

    const retrieveSharedGrowthplan = async (shareCode: any) => {
      try {
        const response = await axiosInstance.get(`/api/shared/get/growthplan/${shareCode}`)
        return response
      } catch (error) {
        throw error
      }
    }

    const retrieveUserGrowthplans = async () => {
      try {
        const response = await axiosInstance.get<GrowthPlan[]>('/api/growthplan/get/growthplans')
        const responseData = response.data
        if (Array.isArray(responseData)) {
          growthplans.value = responseData
        } else {
          growthplans.value = []
        }
        return growthplans.value
      } catch (error) {
        throw error
      }
    }

    const retrieveGrowthplanShareLink = async (growthplanId: any) => {
      try {
        const response = await axiosInstance.get(
          `/api/shared/get/growthplan/sharelink/${growthplanId}`
        )
        return response
      } catch (error) {
        throw error
      }
    }

    const toggleGrowthplanShared = async (growthplanId: any) => {
      try {
        const response = await axiosInstance.patch(
          `/api/shared/toggle/growthplan/${growthplanId}/shared`
        )
        return response
      } catch (error) {
        throw error
      }
    }

    const setSubmittedForFeedback = async (growthplanId: any) => {
      try {
        const response = await axiosInstance.put(
          `/api/growthplan/${growthplanId}/save/submit-for-feedback`
        )
        alertStore.handleResponse(response)
      } catch (error) {
        throw error
      }
    }

    const setGrowthplanCompleted = async (growthplanId: any) => {
      try {
        const response = await axiosInstance.put(`/api/growthplan/${growthplanId}/save/completed`)
        return response
      } catch (error) {
        throw error
      }
    }

    const retrieveAllTodo = async (growthplanId: any) => {
      try {
        const response = await axiosInstance.get(
          `/api/growthplan/${growthplanId}/questions/get/all-todo`
        )
        return response
      } catch (error) {
        throw error
      }
    }

    const saveDone = async (payload: any) => {
      try {
        const growthplanId = payload.growthplanId
        const stepId = payload.stepId
        const questionId = payload.questionId
        const done = payload.done
        const response = await axiosInstance.patch(
          `/api/growthplan/${growthplanId}/question/${stepId}/${questionId}/save/done`,
          {
            done: done
          }
        )
        return response
      } catch (error) {
        console.log("Save done")
        throw error
      }
    }


    return {
      growthplans,
      activeGrowthplans,
      inActiveGrowthplans,
      selectedGrowthplan,
      selectedGrowthplanId,
      selectedGrowthplanName,
      selectedGrowthplanBusinessUnit,
      selectedGrowthplanActive,
      selectedGrowthplanCompleted,
      selectedGrowthplanPercentCompleted,
      selectedGrowthplanShareCode,
      selectedGrowthplanStartDate,
      selectedGrowthplanSubmittedForFeedback,
      selectedGrowthplanCompany,
      currentGrowthplanStep,
      completedQuestions,
      growthplanSteps,
      currentQuestion,
      safeCurrentQuestion,
      showConfirmationModal,
      currentQuestionComponent,
      $reset,
      saveGrowthplanPercentProgress,
      createNewGrowthplan,
      saveCompletedQuestions,
      retrieveCompletedQuestions,
      retrieveSharedGrowthplanSteps,
      retrieveSharedGrowthplan,
      retrieveUserGrowthplans,
      retrieveGrowthplanShareLink,
      toggleGrowthplanShared,
      setSubmittedForFeedback,
      setGrowthplanCompleted,
      retrieveAllTodo,
      saveDone,
      allQuestionsValid
    }
  },
  { persist: true }
)
