import { ref, type Ref } from 'vue'
import { defineStore } from 'pinia'
import { i18n } from '@/utils/i18n'
import { AxiosError, isAxiosError, type AxiosResponse } from 'axios'
import { toast, type ToastOptions } from 'vue3-toastify'
import { GrowthplanNotSetError } from './growthplan'
import * as Sentry from "@sentry/vue";
import { useRouter } from 'vue-router'
import { useAuthStore } from './auth'

export enum AlertVariant {
  success = "success",
  error = "error"
}

export interface AlertData {
  alert: string
  alertVariant: AlertVariant
}

interface AlertStore {
  $reset: () => void
  alert: Ref<string | null>
  alertVariant: Ref<AlertVariant | null>
  setAlert: (alertData: AlertData) => void
  setSuccessAlert: (alertMsg: string) => void
  setErrorAlert: (alertMsg: string) => void
  handleResponse: (serverResponse: AxiosResponse<{ msg: string }>) => void
  handleError: (error: unknown) => void
}

// Localize error message if it's a key in our i18n server errors messages.
// Else just return the text
const localizeAlert = (alertMsg: string) => {
  const i18nkey = `server_errors_text.${alertMsg}`
  return i18n.te(i18nkey) ? i18n.t(i18nkey) : alertMsg
}

export const useAlertStore = defineStore('alert', (): AlertStore => {
  const authStore = useAuthStore()
  // State
  const alert = ref<string | null>(null)
  const alertVariant = ref<AlertVariant | null>(null)

  const router = useRouter()

  // https://pinia.vuejs.org/core-concepts/state.html#Resetting-the-state
  const $reset = () => {
    alert.value = null
    alertVariant.value = null
  }


  // Actions
  const handleError = (error: unknown): void => {

    Sentry.captureException(error)
    // Handling of network errors
    if (isAxiosError(error)) {
      handleAxiosError(error)
    } else if (error instanceof GrowthplanNotSetError) {
      setErrorAlert(error.message)
    } else if (error instanceof Error) {
      setErrorAlert(error.message)
      throw error
    } else {
      setErrorAlert("Unhandeled exception, please contact the system administrator.")
      throw error
    }
  }

  // TODO: What should happen else? Thus in case backend doesn't return a msg on success
  const handleResponse = (serverResponse: AxiosResponse<{ msg: string }>): void => {
    const alertMsg = serverResponse.data.msg
    if (alertMsg) {
      const localizedAlert = localizeAlert(alertMsg)
      setSuccessAlert(localizedAlert)
    } else {
      // If backend hasn't sent a message and we still handle response, just show generic
      // 'Succesfully updated' message
      setSuccessAlert(localizeAlert("updated"))
    }
  }

  const setErrorAlert = (alertMsg: string) => {
    setAlert({
      alertVariant: AlertVariant.error,
      alert: alertMsg
    })
  }
  const setSuccessAlert = (alertMsg: string) => {
    setAlert({
      alertVariant: AlertVariant.success,
      alert: alertMsg
    })
  }

  const setAlert = (alertData: AlertData): void => {
    alert.value = alertData.alert
    alertVariant.value = alertData.alertVariant

    toast(alertData.alert, {
      autoClose: 3000,
      type: alertData.alertVariant,
      theme: "colored",
      position: toast.POSITION.BOTTOM_LEFT,
      clearUrlOnChange: false,
      onClose: () => $reset()
    } as ToastOptions);
  }

  const handleAxiosError = (error: AxiosError<{ msg?: string }>) => {
    if (error.response) {
      const { status, config } = error.response
      const { url } = config

      if (
        url &&
        status === 401 &&
        !['login', 'change-password', 'create-password', 'reset-password'].some((path) =>
          url.includes(path)
        )
      ) {
        authStore.logout()
        setErrorAlert('Session expired, please log in again')

      } else if (status === 422) {
        const msg: string | undefined = error.response.data.msg
        if (msg && /[Ss]ignature verification failed/.test(msg)) {
          authStore.logout(false)
          setErrorAlert('Session expired, please log in again')
        }
      } else if (status === 500) {
        router.push({ name: 'ServerError' })

      } else if (error.response.data.msg != undefined) {
        const alertMsg = error.response?.data.msg
        const localizedAlert = localizeAlert(alertMsg)
        setErrorAlert(localizedAlert)
      } else {
        setErrorAlert("Unknown exception occured. Please contact ProGrowth if this issue persists.")
      }
    } else {
      setErrorAlert("Couldn't connect to server, please check your internet connection")
      return Promise.reject(error)
    }
  }


  return {
    $reset,
    alert,
    alertVariant,
    setAlert,
    setSuccessAlert,
    setErrorAlert,
    handleError,
    handleResponse,
  }
})
