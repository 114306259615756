import * as yup from 'yup'
import { defineStore, storeToRefs } from 'pinia'
import { computed, ref, type Ref } from 'vue'
import { useGrowthplanStore } from './growthplan'
import axiosInstance from '@/axios'


export interface Feedback {
  id?: number
  feedback_text: string
  growthplan_id: number
  growthplan_step_id: number
  seen: boolean
}

export interface GeneralFeedback {
  id: number
  feedback: string
  growthplan_id: number
}

interface FeedbackStore {
  feedback: Ref<Feedback[]>
  savingFeedback: Ref<boolean>
  feedbackLastSaved: Ref<number | null>
  lastFeedbackSaveSuccesfull: Ref<boolean>
  currentQuestionFeedback: Ref<Feedback[]>
  retrieveFeedback: () => Promise<Feedback[]>
}

export interface DashboardFeedback {
    id: number
    name: string
    position: number
    feedback: Feedback[]
}

export const feedbackSchema = yup.object({
  feedback_text: yup.string().required().max(1000),
})

export const useFeedbackStore = defineStore('feedback', (): FeedbackStore => {
  const growthplanStore = useGrowthplanStore()
  const { currentGrowthplanStep, selectedGrowthplanId } = storeToRefs(growthplanStore)

  const feedback = ref<Feedback[]>([])
  const savingFeedback = ref(false)
  const feedbackLastSaved: Ref<number | null> = ref(null)
  const lastFeedbackSaveSuccesfull: Ref<boolean> = ref(true)

  const currentQuestionFeedback = computed(() => {
    return feedback.value.filter(fb => fb.growthplan_step_id == currentGrowthplanStep.value.id)
  })
  const retrieveFeedback = async ()  => {
    try {
      const response = await axiosInstance.get<Feedback[]>(`/api/growthplan/${selectedGrowthplanId.value}/get/feedback/all`)
      const retrievedFeedback = response.data
      if (Array.isArray(response.data))  {
        feedback.value = retrievedFeedback
        return retrievedFeedback
      }
      return []

    } catch (error) {
      throw error
    }
  }

  return {
    // State
    feedback,
    savingFeedback,
    lastFeedbackSaveSuccesfull,
    feedbackLastSaved,
    currentQuestionFeedback,
    retrieveFeedback
  }
})
