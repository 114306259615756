import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/HomeView.vue'),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: () => import('@/views/ChangePassword.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/client/login',
      name: 'clientLogin',
      component: () => import('../views/client/login/LoginView.vue'),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/client/growthplan/start',
      name: 'StartPage',
      component: () => import('@/views/client/growthplan/ClientGrowthplanStartPageView.vue'),
      meta: {
        requiresAuth: true,
        role: 'User'
      }
    },
    {
      path: '/client/growthplan/details',
      name: 'ClientCreateGrowthplan',
      component: () => import('@/views/client/growthplan/ClientCreateGrowthplan.vue'),
      meta: {
        requiresAuth: true,
        role: 'User'
      }
    },
    {
      path: '/client/growthplan',
      name: 'ClientGrowthplanContainer',
      component: () => import('@/views/client/growthplan/ClientGrowthplanContainer.vue'),
      meta: {
        requiresAuth: true,
        role: 'User'
      }
    },
    {
      path: '/client/general-feedback',
      name: 'ClientGeneralFeedback',
      component: () => import('@/views/client/dashboard/ClientGeneralFeedback.vue'),
      meta: {
        requiresAuth: true,
        role: 'User'
      }
    },
    {
      path: '/consultant/growthplan',
      name: 'ConsultantGrowthplanContainer',
      component: () => import('@/views/consultant/ConsultantGrowthplanContainer.vue'),
      meta: {
        requiresAuth: true,
        role: 'Consultant'
      }
    },
    {
      path: '/consultant/client-registry',
      name: 'ConsultantClientRegistry',
      component: () => import('@/views/consultant/ConsultantClientRegistry.vue'),
      meta: {
        requiresAuth: true,
        role: 'Consultant'
      }
    },
    {
      path: '/client/reset-password',
      name: 'ClientResetPasswordEmail',
      component: () => import('@/views/client/ClientResetPasswordEmailView.vue')
    },
    {
      path: '/consultant/reset-password',
      name: 'ConsultantResetPasswordEmail',
      component: () => import('@/views/consultant/ConsultantResetPasswordEmailView.vue')
    },
    {
      path: '/client/reset-password/:token',
      name: 'ClientResetPassword',
      component: () => import('@/views/client/ClientResetPasswordView.vue')
    },
    {
      path: "/client/create-password/:token",
      name: "ClientFirstLoginPasswordReset",
      component: () =>
        import("@/views/client/ClientResetPasswordFirstTimeView.vue"),
    },
    {
      path: '/consultant/reset-password/:token',
      name: 'ConsultantResetPassword',
      component: () => import('@/views/consultant/ConsultantResetPasswordView.vue')
    },
    {
      path: "/consultant/create-password/:token",
      name: "ConsultantFirstLoginPasswordReset",
      component: () =>
        import("@/views/consultant/ConsultantResetPasswordFirstTimeView.vue"),
    },
    {
      path: '/client/dashboard',
      name: 'ClientDashboard',
      component: () => import('../views/client/dashboard/ClientDashboardView.vue'),
      meta: {
        requiresAuth: true,
        role: 'User'
      }
    },
    {
      path: '/consultant/login',
      name: 'ConsultantLogin',
      component: () => import('../views/consultant/ConsultantLoginView.vue'),
    },
    {
      path: '/consultant/dashboard',
      name: 'ConsultantDashboard',
      component: () => import('../views/consultant/ConsultantDashboardView.vue'),
      meta: {
        requiresAuth: true,
        role: 'Consultant'
      }
    },
    {
      path: '/admin/login',
      name: 'AdminLogin',
      component: () => import('../views/admin/AdminLoginView.vue'),
    },
    {
      path: '/admin/dashboard',
      name: 'AdminDashboard',
      component: () => import('../views/admin/AdminDashboardView.vue'),
      meta: {
        requiresAuth: true,
        role: "Admin"
      }
    },
    {
      path: '/admin/growthplan',
      name: 'AdminGrowthplanContainer',
      component: () => import('@/views/admin/AdminGrowthplanContainer.vue'),
      meta: {
        requiresAuth: true,
        role: "Admin"
      }
    },
    {
      path: '/admin/growthplan-view',
      name: 'AdminGrowthplanView',
      component: () => import('@/views/admin/AdminGrowthplanView.vue'),
      meta: {
        requiresAuth: true,
        role: "Admin"
      }
    },
    {
      path: "/admin/client-details/:id",
      name: 'AdminClientDetails',
      component: () => import('../views/IndividualClientDetails.vue'),
      meta: {
        requiresAuth: true,
        role: "Admin"
      }
    },
    {
      path: "/admin/client-edit-details/:id",
      name: "AdminClientEditDetails",
      component: () => import("../views/IndividualClientEditDetails.vue"),
      meta: {
        requiresAuth: true,
        role: "Admin",
      },
    },
    {
      path: "/admin/clients",
      name: "AdminClientOverview",
      component: () => import("../views/admin/AdminClientOverview.vue"),
      meta: {
        requiresAuth: true,
        role: "Admin",
      },
    },
    {
      path: "/admin/consultants",
      name: "AdminConsultantOverview",
      component: () => import("../views/admin/AdminConsultantOverview.vue"),
      meta: {
        requiresAuth: true,
        role: "Admin",
      },
    },
    {
      path: "/admin/consultant-registry",
      name: "AdminConsultantRegistry",
      component: () => import("../views/admin/AdminConsultantRegistry.vue"),
      meta: {
        requiresAuth: true,
        role: "Admin",
      },
    },
    {
      path: "/admin/consultant-details/:id",
      name: "AdminConsultantDetails",
      component: () => import("../views/IndividualConsultantDetails.vue"),
      meta: {
        requiresAuth: true,
        role: "Admin",
      },
    },
    {
      path: "/admin/consultant-edit-details/:id",
      name: "AdminConsultantEditDetails",
      component: () => import("../views/IndividualConsultantEditDetails.vue"),
      meta: {
        requiresAuth: true,
        role: "Admin",
      },
    },
    {
      path: "/admin/edit-texts",
      name: "AdminEditText",
      component: () => import("../views/admin/AdminEditText.vue"),
      meta: {
        requiresAuth: true,
        role: "Admin",
      },
    },
    {
      path: '/growthplan/alltodo',
      name: 'AllTodo',
      component: () => import('../views/AllTodo.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/consultant/client-details/:id",
      name: 'ConsultantClientDetails',
      component: () => import('../views/IndividualClientDetails.vue'),
      meta: {
        requiresAuth: true,
        role: "Consultant"
      }
    },
    {
      path: "/consultant/client-edit-details/:id",
      name: "ConsultantClientEditDetails",
      component: () => import("../views/IndividualClientEditDetails.vue"),
      meta: {
        requiresAuth: true,
        role: "Consultant",
      },
    },
    {
      path: "/share/growthplan/:code",
      name: "SharedGrowthplanContainer",
      component: () =>
        import("../views/shared/SharedGrowthplanContainer.vue"),
    },
    {
      path: "/server-error",
      name: 'ServerError',
      component: () =>
        import("../views/ServerError.vue")

    },
    {
      path: "/:pathMatch(.*)*",
      name: 'NotFound',
      component: () =>
        import("../views/NotFound.vue")

    },
  ],
  // Always scoll to the top when navigating, unless a position was saved
  scrollBehavior(_, __, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

router.beforeEach((to, _) => {
  const authStore = useAuthStore()
  if (to.meta.requiresAuth && !authStore.loggedIn) {
    return {
      path: '/'
    }
  }
})

export default router
