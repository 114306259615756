<template>
    <ConfirmationModal :show="showUpsellModal" @closed="closeUpsellModal" @confirmed="closeUpsellModal"
        :has-confirm="false">
        <template #content>
            <h3 class="text-2xl text-gp-blue font-bold">
                {{ t(getI18nKey('growthplans_expired_heading')) }}
            </h3>
            <p>
                {{ t(getI18nKey('growthplans_expired_subheading')) }}
                <br />
                {{ props.userData.consultant.first_name }}
                {{ props.userData.consultant.last_name }}:
                <a class="text-gp-blue hover:underline" :href="'mailto:' + props.userData.consultant.email">
                    {{ props.userData.consultant.email }}
                </a>
            </p>
        </template>
    </ConfirmationModal>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import { type UserAccount } from "@/stores/client"
import ConfirmationModal from '@/components/ConfirmationModal.vue'

const getI18nKey = (key: string) => `client_dashboard_text.${key}`

const showUpsellModal = defineModel<boolean>({required: true})

interface Props {
    userData: UserAccount
}

const props = defineProps<Props>();

const closeUpsellModal = () => {
    showUpsellModal.value = false
}

</script>
