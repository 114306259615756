import enAllSchema from "../../languages/en.json"
import { languages } from "@/languages"
import { createI18n, type PickupPaths } from 'vue-i18n';
import { setLocale } from "yup";

// enAllSchema contains 'all texts', meaning all texts that can be available at any point to authorized users

// The schema of 'general texts', texts that even unauthorized users get to see
// also to only authorized users such as 
const enGeneralSchema = languages.en

export type AllMessageKey = PickupPaths<typeof enAllSchema>
export type GeneralMessageKey = PickupPaths<typeof enGeneralSchema>

// A message is either defined as below, or natively from Yup
export type ValidationMessage = CustomMessage | string;

// Used to initialize i18n with key type hints
export type GeneralMessageSchema = typeof enGeneralSchema
export type AllMessageSchema = typeof enAllSchema

interface TemplateMapping {
  [index: string]: any
}

interface CustomMessage {
  key: AllMessageKey,
  values?: TemplateMapping
}

export const numberPatt = /^[0-9]+$/
export const atLeastOneNum = /(?=.*[0-9])/
export const atLeastOneUpcase = /(?=.*[A-Z])/
export const atLeastOneSpecial = /(?=.*[@#$%^&+=!])/

setLocale({
  mixed: {
    default: (): string => ("Field is invalid!"),
    required: (): CustomMessage => ({ key: "validation_errors_text.required" }),
    notType: (): CustomMessage => ({key: "validation_errors_text.regex"})
  },
  string: {
    matches: ({ regex }) => {
      switch (regex) {
        case numberPatt:
          return {key: "validation_errors_text.number"} as CustomMessage
        case atLeastOneNum:
          return {key: "validation_errors_text.number"} as CustomMessage
        case atLeastOneUpcase:
          return {key: "validation_errors_text.up_case"} as CustomMessage
        case atLeastOneSpecial:
          return {key: "validation_errors_text.special_char"} as CustomMessage

        default:
          return {key: "validation_errors_text.regex"} as CustomMessage
      }
    },
    email: (): CustomMessage => {
      return { key: 'validation_errors_text.email' }
    },
    max: ({ max }): CustomMessage => {
      return {
        key: "validation_errors_text.max",
        values: { length: max }
      }
    },
    min: ({ min }): CustomMessage => {
      return {
        key: "validation_errors_text.min",
        values: { length: min }
      }
    }
  }
})


const i18nInstance = createI18n<[GeneralMessageSchema], 'en' | 'fi'>({
  legacy: false,
  allowComposition: true,
  locale: 'en',
  fallbackLocale: 'en',
  messages: languages,
})

export default i18nInstance

// https://stackoverflow.com/a/71449708/8571515
export const i18n = i18nInstance.global
