<template>
  <Teleport to="body">
    <transition enter-active-class="transition ease-out duration-200 transform" enter-from-class="opacity-0"
      enter-to-class="opacity-100" leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100" leave-to-class="opacity-0">
      <div v-show="show" ref="modalBackdrop" class="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50">
        <div v-if="show" class="flex min-h-screen items-center justify-center pt-24 text-center">
          <transition enter-active-class="transition ease-out duration-300 transform"
            enter-from-class="opacity-0 translate-y-10 scale-95" enter-to-class="opacity-100 translate-y-0 scale-100"
            leave-active-class="ease-in duration-200" leave-from-class="opacity-100 translate-y-0 scale-100"
            leave-to-class="opacity-0 translate-y-10 translate-y-0 scale-95">
            <div class="w-1/2 overflow-visible rounded-lg bg-white p-8 text-left shadow-xl" role="dialog" ref="modal"
              aria-modal="true" aria-labelledby="modal-headline">
              <div class="mb-4">
                <slot name="content"></slot>
              </div>
              <button class="btn-blue mr-4" @click="onConfirm" id="modal-confirm-button" data-test="modal-confirm"
                v-if="props.hasConfirm" :disabled="props.confirmDisabled">
                {{ t('credentials_text.confirm') }}
              </button>
              <button class="btn-danger" @click="onClose" id="modal-close-button" data-test="modal-close">
                {{ t('credentials_text.close') }}
              </button>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </Teleport>
</template>
<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n'

// Init composable
const { t } = useI18n()

interface Props {
  show: boolean
  hasConfirm?: boolean
  context?: any
  confirmDisabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  hasConfirm: true,
  confirmDisabled: false
})

const modal = ref(null);

onClickOutside(modal, _ => {
  onClose()
})

// Emits
const emit = defineEmits(['confirmed', 'closed'])

// Methods
const onConfirm = () => {
  if (props.context != null) {
    emit('confirmed', props.context)
  } else {
    emit('confirmed')
  }
}

const onClose = () => {
  emit('closed')
}
</script>
