import { computed, ref, type Ref, type ComputedRef } from 'vue'
import { defineStore } from 'pinia'
import axiosInstance from '@/axios'
import type { AxiosResponse } from 'axios'
import { useAlertStore } from './alerts'
import * as yup from 'yup'
import { atLeastOneNum, atLeastOneUpcase, atLeastOneSpecial } from '@/utils/i18n'
import { useRouter } from 'vue-router'
import { useGrowthplanStore } from './growthplan'
import { useLanguageStore } from './languages'

export enum Role {
  Admin = "Admin",
  Consultant = "Consultant",
  User = "User",
}

interface AuthStore {
  token: Ref<string | null>
  role: Ref<Role | null>

  $reset: () => void
  retrieveToken: (credentials: Credentials) => Promise<void>
  retrieveConsultantToken: (credentials: Credentials) => Promise<void>
  retrieveAdminToken: (credentials: Credentials) => Promise<void>
  destroyToken: () => Promise<void>
  logout: (destoryTheToken?: boolean) => Promise<void>
  changePassword: (passwords: Passwords) => Promise<any>
  registerClient: (credentials: ClientCredentials) => Promise<any>
  registerConsultant: (credentials: ConsultantDetails) => Promise<any>
  updateClientDetails: (credentials: UpdateClientDetails) => Promise<void>
  updateConsultantDetails: (consultant: ConsultantDetails, id: string) => Promise<any>
  sendPasswordResetEmailUser: (email: string) => Promise<void>
  resetPasswordUser: (credentials: ResetUserPassword, token: string) => Promise<void>
  resetPasswordUserFirstLogin: (credentials: ResetUserFirstLoginPassword, token: string) => Promise<void>
  sendPasswordResetEmailConsultant: (email: string) => Promise<void>
  resetPasswordConsultant: (credentials: ResetConsultantPassword, token: string) => Promise<void>
  resetPasswordConsultantFirstLogin: (
    credentials: ResetConsultantFirstLoginPassword,
    token: string
  ) => Promise<void>
  loggedIn: ComputedRef<boolean>
  isAdmin: ComputedRef<boolean>
  isConsultant: ComputedRef<boolean>
}

interface Credentials {
  email: string
  password: string
}

interface Passwords {
  current_password: string
  new_password: string
  new_password2: string
}

interface ClientCredentials {
  first_name: string,
  last_name: string,
  email: string,
  company: string,
  address: string,
  city: string,
  zipcode: string,
  phone_number: string,
}


interface UpdateClientDetails {
  clientId: string
  firstName: string
  lastName: string
  companyName: string
  businessUnit: string
  email: string
  phone: string
  street: string
  zipcode: string
  city: string
}

interface ConsultantDetails {
  first_name: string
  last_name: string
  email: string
  phone_number: string
}

interface ResetUserPassword {
  new_password: string
  new_password2: string
}

interface ResetUserFirstLoginPassword {
  current_password: string
  new_password: string
  new_password2: string
}

interface ResetConsultantPassword {
  new_password: string
  new_password2: string
}

interface ResetConsultantFirstLoginPassword {
  current_password: string
  new_password: string
  new_password2: string
}

interface RetrieveTokenResponse {
  access_token: string
  refresh_token: string
  role: Role
}

interface LogoutReponse {
  msg: string
}


export const passwordField = yup.string().required().min(8).max(250).matches(atLeastOneSpecial).matches(atLeastOneUpcase).matches(atLeastOneNum)

export const userDetailsSchema = yup.object({
  company: yup.string().required().max(50),
  first_name: yup.string().required().max(50),
  last_name: yup.string().required().max(50),
  email: yup.string().email().required().max(250),
  phone_number: yup.string().required().max(20),
  address: yup.string().required().max(50),
  zipcode: yup.string().required().max(10),
  city: yup.string().required().max(80),
})
export const consultantDetailsSchema = yup.object({
  first_name: yup.string().required().max(50),
  last_name: yup.string().required().max(50),
  email: yup.string().email().required().max(250),
  phone_number: yup.string().required().max(20),
})


// NOTE: These are all correctly migrated
// TODO: Implement better error handling
export const useAuthStore = defineStore(
  'auth',
  (): AuthStore => {
    const alertStore = useAlertStore()
    const growthplanStore = useGrowthplanStore()
    const languageStore = useLanguageStore()

    const router = useRouter()

    // State
    const token = ref('')
    const role = ref()

    // Actions
    // https://pinia.vuejs.org/core-concepts/state.html#Resetting-the-state
    const $reset = () => {
      token.value = ''
      role.value = ''
    }

    const logout = async (destoryTheToken = true) => {
      try {
        if (destoryTheToken) await destroyToken()
        $reset()
        router.push({ name: 'home' }).finally(() => {
          growthplanStore.$reset()
          languageStore.$reset()
        })
      } catch (error) {
        alertStore.handleError(error)
      }
    }

    const retrieveToken = async (credentials: Credentials) => {
      try {
        const response: AxiosResponse<RetrieveTokenResponse> = await axiosInstance.post(
          '/api/login/user',
          credentials
        )
        token.value = response.data.access_token
        role.value = response.data.role
      } catch (error) {
        throw error
      }
    }

    const retrieveConsultantToken = async (credentials: Credentials) => {
      try {
        const response: AxiosResponse<RetrieveTokenResponse> = await axiosInstance.post(
          '/api/login/consultant',
          credentials
        )
        token.value = response.data.access_token
        role.value = response.data.role
      } catch (error) {
        throw error
      }
    }

    const retrieveAdminToken = async (credentials: Credentials) => {
      try {
        const response: AxiosResponse<RetrieveTokenResponse> = await axiosInstance.post(
          '/api/login/admin',
          credentials
        )
        token.value = response.data.access_token
        role.value = response.data.role
      } catch (error) {
        throw error
      }
    }

    const destroyToken = async () => {
      if (loggedIn.value) {
        try {
          const response: AxiosResponse<LogoutReponse> = await axiosInstance.delete(
            '/api/logout/access-token'
          )
          $reset()
          alertStore.handleResponse(response)
        } catch (error) {
          $reset()
          throw error
        }
      } else {
        $reset()
      }
    }

    const changePassword = async (passwords: Passwords) => {
      try {
        return await axiosInstance.post('/api/change-password', passwords)
      } catch (error) {
        throw error
      }
    }

    const registerClient = async (credentials: ClientCredentials) => {
      try {
        return await axiosInstance.post('/api/register/user', credentials)
      } catch (error) {
        throw error
      }
    }

    const registerConsultant = async (credentials: ConsultantDetails) => {
      try {
        return await axiosInstance.post('/api/register/consultant', credentials)
      } catch (error) {
        throw error
      }
    }

    const updateClientDetails = async (credentials: UpdateClientDetails) => {
      try {
        const response = await axiosInstance.put(
          `/api/user/${credentials.clientId}/update/details`,
          credentials
        )
        alertStore.handleResponse(response)
      } catch (error) {
        throw error
      }
    }

    const updateConsultantDetails = async (consultant: ConsultantDetails, id: string) => {
      try {
        return await axiosInstance.put(
          `/api/consultant/${id}/update/details`,
          consultant
        )
      } catch (error) {
        throw error
      }
    }

    const sendPasswordResetEmailUser = async (email: string) => {
      try {
        const response = await axiosInstance.post('/api/reset-password-email/user', { email })
        alertStore.handleResponse(response)
      } catch (error) {
        throw error
      }
    }

    const resetPasswordUser = async (credentials: ResetUserPassword, token: string) => {
      try {
        const response = await axiosInstance.post(`/api/reset-password/user/${token}`, credentials)
        alertStore.handleResponse(response)
      } catch (error) {
        throw error
      }
    }

    const resetPasswordUserFirstLogin = async (credentials: ResetUserFirstLoginPassword, token: string) => {
      try {
        const response = await axiosInstance.post(
          `/api/reset-password-first-time/user/${token}`,
          credentials
        )
        alertStore.handleResponse(response)
      } catch (error) {
        throw error
      }
    }

    const sendPasswordResetEmailConsultant = async (email: string) => {
      try {
        const response = await axiosInstance.post('/api/reset-password-email/consultant', {
          email
        })
        alertStore.handleResponse(response)
      } catch (error) {
        throw error
      }
    }

    const resetPasswordConsultant = async (credentials: ResetConsultantPassword, token: string) => {
      try {
        const response = await axiosInstance.post(`/api/reset-password/consultant/${token}`, credentials)
        alertStore.handleResponse(response)
      } catch (error) {
        throw error
      }
    }

    const resetPasswordConsultantFirstLogin = async (
      credentials: ResetConsultantFirstLoginPassword,
      token: string
    ) => {
      try {
        const response = await axiosInstance.post(
          `/api/reset-password-first-time/consultant/${token}`,
          credentials
        )
        alertStore.handleResponse(response)
      } catch (error) {
        throw error
      }
    }

    const loggedIn = computed(() => token.value !== '')
    const isAdmin = computed(() => role.value === 'admin')
    const isConsultant = computed(() => role.value === 'consultant')

    return {
      $reset,
      token,
      role,
      retrieveConsultantToken,
      retrieveAdminToken,
      destroyToken,
      logout,
      changePassword,
      registerClient,
      registerConsultant,
      updateClientDetails,
      updateConsultantDetails,
      sendPasswordResetEmailUser,
      resetPasswordUser,
      resetPasswordUserFirstLogin,
      sendPasswordResetEmailConsultant,
      resetPasswordConsultant,
      resetPasswordConsultantFirstLogin,
      loggedIn,
      isAdmin,
      isConsultant,
      retrieveToken
    }
  },
  { persist: true }
)
