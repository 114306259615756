import { defineStore, storeToRefs } from 'pinia'
import axiosInstance from '@/axios'

import { ref, type Ref } from 'vue'
import { type GrowthPlan, useGrowthplanStore } from './growthplan'

interface ClientStore {
  userData: Ref<UserAccount | null>
  userActiveGrowthplan: Ref<GrowthPlan | null>
  retrieveUserProfile: () => Promise<any>
  retrieveUserDetails: (userId: number) => Promise<any>
  retrieveUserActiveGrowthplan: () => Promise<GrowthPlan>
  retrieveAllowedNewGrowthplans: () => Promise<any>
  updateClientDetails: (userAccount: UserCredentials, id: number) => Promise<any>
}

export interface UserAccount {
  id: number,
  role: "User" | "Consultant" | "Admin",
  first_name: string,
  last_name: string,
  email: string,
  company: string,
  date_created: string,
  address: string,
  city: string,
  zipcode: string,
  phone_number: string,
  verified: boolean,
  growthplan_credit: boolean,
  consultant: {
    email: string,
    first_name: string,
    last_name: string
  }
}

export interface UserCredentials {
  first_name: string,
  last_name: string,
  email: string,
  company: string,
  address: string,
  city: string,
  zipcode: string,
  phone_number: string,
}

// TODO: Do these functions need to return anything or just mutate the state? Look into this
// NOTE: These are all correctly migrated
// TODO: Implement better error handling
export const useClientStore = defineStore('client', (): ClientStore => {
  // State
  const growthplanStore = useGrowthplanStore()
  const {selectedGrowthplan} = storeToRefs(growthplanStore)
  const userData: Ref<UserAccount | null> = ref(null)
  const userActiveGrowthplan: Ref<GrowthPlan | null> = ref(null)


  // Actions
  const retrieveUserProfile = async () => {
    try {
      return await axiosInstance.get('/api/user/get/profile')
    } catch (error) {
      throw error
    }
  }

  const retrieveUserDetails = async (userId: number) => {
    try {
      return await axiosInstance.get(`/api/user/${userId}/get/details`)
    } catch (err) {
      throw err
    }
  }

  const retrieveUserActiveGrowthplan = async () => {
    try {
      const response = await axiosInstance.get<GrowthPlan | {msg: string}>(`/api/user/get/active-growthplan`)
      const activeGrowthplan = response.data
      if ("msg" in activeGrowthplan) {
        userActiveGrowthplan.value = null
      } else {
        userActiveGrowthplan.value = activeGrowthplan as GrowthPlan
        selectedGrowthplan.value = activeGrowthplan as GrowthPlan
      }
      return activeGrowthplan
    } catch (err) {
      throw err
    }
  }

  const retrieveAllowedNewGrowthplans = async () => {
    try {
      const response = await axiosInstance.get('/api/user/get/allowed-new-growthplan')
      return response
    } catch (error) {
      throw error
    }
  }

const updateClientDetails = async (userCredentials: UserCredentials, id: number) => {
    try{
      return await axiosInstance.put(`/api/user/${id}/update/details`, userCredentials)
    } catch (error) {
      throw error
    }
  }

  return {
    // State
    userData,
    userActiveGrowthplan,
    // Actions
    retrieveUserProfile,
    retrieveUserActiveGrowthplan,
    retrieveUserDetails,
    retrieveAllowedNewGrowthplans,
    updateClientDetails,
  }
})
