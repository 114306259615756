<template>
  <footer class="mt-[20vh] bg-gp-blue px-[5vw] pt-[5vh] text-white">
    <div class="grid grid-cols-1 space-y-8 md:grid-cols-6 md:space-x-4 md:space-y-0">
      <div class="col-span-1 md:col-span-3">
        <h2 class="mb-2 font-bold">
          {{ t('footer_text.footer_heading_1') }}
        </h2>
        <p class="footer-text">
          {{ t('footer_text.footer_content_1') }}
        </p>
      </div>
      <div class="col-start-1 md:col-start-4">
        <h2 class="mb-2 font-bold">
          {{ t('footer_text.footer_heading_2') }}
        </h2>
        <ul class="list-unstyled">
          <li class="footer-list-item">
            <a class="footer-link" target="_blank" href="https://www.progrowth.fi/palvelut/">{{
              t('footer_text.sitemap_item_1')
              }}</a>
          </li>
          <li class="footer-list-item">
            <a class="footer-link" target="_blank" href="https://www.progrowth.fi/valmennukset/">{{
              t('footer_text.sitemap_item_2')
              }}</a>
          </li>
          <li class="footer-list-item">
            <a class="footer-link" target="_blank" href="https://www.progrowth.fi/asiantuntijat/">{{
              t('footer_text.sitemap_item_3')
              }}</a>
          </li>
          <li class="footer-list-item">
            <a class="footer-link" target="_blank" href="https://blogi.progrowth.fi/blogi">{{
              t('footer_text.sitemap_item_4')
              }}</a>
          </li>
          <li class="footer-list-item">
            <a class="footer-link" target="_blank" href="https://www.progrowth.fi/yhteystiedot/">{{
              t('footer_text.footer_heading_3')
              }}</a>
          </li>
        </ul>
      </div>
      <div class="col-start-1 md:col-start-5">
        <h2 class="mb-2 font-bold">
          {{ t('footer_text.footer_heading_3') }}
        </h2>
        <p class="footer-text" data-test="footer-address">{{ t('footer_text.footer_content_3') }}</p>
        <ul class="flex space-x-4">
          <li>
            <a target="_blank" href="https://www.facebook.com/progrowth.oy/">
              <font-awesome-icon class="footer-icon" :icon="['fab', 'facebook']" />
            </a>
          </li>
          <li>
            <a target="_blank" href="https://www.linkedin.com/company/pro-growth-oy/">
              <font-awesome-icon class="footer-icon" :icon="['fab', 'linkedin']" />
            </a>
          </li>
        </ul>
      </div>
      <div class="col-start-1 md:col-start-6">
        <img :src="logoImage" alt="Growthplan Logo Image" class="w-11/12" />
      </div>
    </div>
    <div class="grid grid-cols-1 py-8 text-xs md:grid-cols-2">
      <div>
        <h4 class="footer-copyright-heading" data-test="footer-copyright">
          {{ t('footer_text.copyright', {year: currentYear}) }}
        </h4>
      </div>
      <div class="text-end">
        <a to="/privacy-policy" class="footer-link p-t-c">{{ t('footer_text.privacy') }}</a>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import logoImage from '@/assets/images/ProGrowth-Logo-scaled.png'

const { t } = useI18n()
const currentYear = new Date().getFullYear()
</script>
