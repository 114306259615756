<template class="font-sans">
  <Suspense>
    <transition name="load">
      <div>
        <div v-show="showLoading">
          <LoadingOverlay />
        </div>
        <NavbarComponent />
        <div class="container mx-auto min-h-screen">
          <RouterView />
        </div>
        <FooterComponent />
      </div>
    </transition>
  </Suspense>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import { useLanguageStore } from '@/stores/languages'
import { RouterView } from 'vue-router'

import FooterComponent from '@/components/FooterComponent.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import NavbarComponent from '@/components/NavbarComponent.vue'
import { storeToRefs } from 'pinia'
import { useAlertStore } from './stores/alerts'
import { useClientStore } from './stores/client'
import { useI18n } from 'vue-i18n'
import { onMounted, ref } from 'vue'


const languageStore = useLanguageStore()
const authStore = useAuthStore()
const clientStore = useClientStore()
const alertStore = useAlertStore()
const { mergeLocaleMessage } = useI18n()

const { showLoading } = storeToRefs(languageStore);


const initActiveGrowthplanForUser = async () => {
  if (authStore.loggedIn && authStore.role === "User") {
    try {
      await clientStore.retrieveUserActiveGrowthplan()
    } catch (error) {
      alertStore.handleError(error)
    }
  }
}

onMounted(async () => {
  showLoading.value = true
  await languageStore.handleLoadingLang(languageStore.selectedLanguage);

  await initActiveGrowthplanForUser()
  mergeLocaleMessage("en", {
    client_login_text: {
      email_placeholder: "name{'@'}email.fi",
    }
  })
  mergeLocaleMessage("fi", {
    client_login_text: {
      email_placeholder: "nimi{'@'}sähköposti.fi",
    }
  })
  // TODO: This needs to be added to the DB
  mergeLocaleMessage("en", {
    "landing_text": {
      hero_heading: "“The world is full of good plans that were never executed.” This is why we invented the Growth Plan™.",
      hero_sub_heading: "Since 2015, Pro Growth Consulting has collaborated with clients to develop the Growth Plan. Hundreds of companies have created a Growth Plan for their business. Key benefits of the Growth Plan method and tool include: ",
      hero_bullet_1: "Focus on critical priorities",
      hero_bullet_2: "Establishment of a common understanding",
      hero_bullet_3: "Achievement of growth targets"
    }
  })
  mergeLocaleMessage("fi", {
    "landing_text": {
      hero_heading: "“Maailma on täynnä hyviä suunnitelmia, joita ei koskaan toteutettu.” Tämän takia on kehitetty Growth Plan™.",
      hero_sub_heading: "Vuodesta 2015 lähtien Pro Growth Consulting on kehittänyt Growth Plania asiakkaidensa kanssa. Satoja yrityksiä on luonut Growth Planin liiketoiminnalleen. Growth Plan -menetelmän ja työkalun keskeisiä etuja ovat:",
      hero_bullet_1: "Keskitytään oikeisiin asioihin",
      hero_bullet_2: "Saavutetaan yhteinen ymmärrys kasvun keinoista",
      hero_bullet_3: "Suunnitelman toteutus onnistuu"
    }
  })

  showLoading.value = false
})


</script>
