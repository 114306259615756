import axios, { type AxiosInstance, AxiosError } from 'axios'
import { useAuthStore } from '@/stores/auth'

// Init an Axios instance
const axiosInstance: AxiosInstance = axios.create()

axiosInstance.interceptors.request.use(
  (config) => {
    // Init the store within the function to prevent race conditions
    const authStore = useAuthStore()
    const token: string | null = authStore.token
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
