import('@/assets/main.css')

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import i18nInstance from '@/utils/i18n'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import * as Sentry from '@sentry/vue'
import VueDraggableResizable from 'vue-draggable-resizable'
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import App from './App.vue'
import router from './router'

/* add icons to the library */
library.add(faFacebook, faLinkedin)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app = createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('vue-draggable-resizable', VueDraggableResizable)

if (import.meta.env.VITE_SENTRY_DSN != undefined) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [],
  })
}

app.use(pinia)
app.use(i18nInstance)
app.use(router)
app.use(Vue3Toastify, {
  limit: 5,
  clearOnUrlChange: false
} as ToastContainerOptions)

app.mount('#app')
